<template>
  <v-container>
    <v-subheader>
      <v-btn
        icon
        small
        to="/"
      >
        <v-icon small>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <strong class="ml-2">{{ $t('help') }}</strong>
    </v-subheader>
    
    <v-container class="pt-0">
      <v-row
        dense
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-sheet
            class="rounded-pill btn_bg"
          >
            <v-list-item
              to="/ticket?a=1"
              class="box-no-bg"
            >
              <v-list-item-icon>
                <v-icon>mdi-help-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('wdgd') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-sheet
            class="rounded-pill btn_bg"
          >
            <v-list-item
              to="/ticket"
              class="box-no-bg"
            >
              <v-list-item-icon>
                <v-icon>mdi-comment-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('tjxgd') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-sheet
            class="rounded-pill btn_bg"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>support@YeloBet.com</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="doCopy('support@YeloBet.com')"
                >
                  <v-icon small>
                    mdi-content-copy
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-sheet>
        </v-col>

        <v-col cols="12">
          <v-subheader>
            {{ $t('cjwt') }}
          </v-subheader>

          <v-row
            no-gutters
            justify="center"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                clearable
                solo-inverted
                flat
                rounded
                hide-details
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <div class="d-flex justify-center">
            <v-chip-group
              v-model="category"
              mandatory
              active-class="primary--text"
            >
              <v-chip value="">
                {{ $t('quanbu') }}
              </v-chip>
              <v-chip
                v-for="(item, index) in cate_list"
                :key="'cate_'+index"
                :value="item.key"
              >
                {{ item.name }}
              </v-chip>
            </v-chip-group>
          </div>
        </v-col>
        <template v-for="(c, i_c) in cate_list">
          <v-col
            v-if="category=='' || (category!='' && category==c.key)"
            :key="'c_'+i_c"
            cols="12"
            md="6"
          >
            <v-sheet
              class="rounded-lg nav_bg"
            >
              <v-subheader>
                {{ c.name }}
                <v-spacer />
                <v-btn
                  text
                  @click="seemore(c.key)"
                >
                  {{ $t('gengduo') }}
                </v-btn>
              </v-subheader>
              <v-list
                dense
                color="box_bg"
              >
                <template v-for="(h, i_h) in help_list">
                  <v-list-item
                    v-if="h.cate_id==c.id"
                    :key="'h_'+i_h"
                    @click="showdetail(h)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ h.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </v-sheet>
          </v-col>
        </template>
      </v-row>
    </v-container>

    <v-dialog
      v-model="detail"
      max-width="500"
      scrollable
    >
      <v-card
        color="box_bg"
      >
        <v-card-title class="text-h6">
          {{ chooseitem.title }}
          <v-spacer />
        </v-card-title>

        <v-card-text
          style="max-height: 400px;"
          v-html="chooseitem.content"
        />

        <v-divider class="opacity-3" />

        <v-card-actions class="py-3">
          <v-btn
            small
            depressed
            @click="helpuseful(chooseitem.id, 1)"
          >
            <v-icon left>
              mdi-thumb-up
            </v-icon>
            {{ $t('youbz') }}
          </v-btn>
          <v-btn
            small
            depressed
            @click="helpuseful(chooseitem.id, -1)"
          >
            <v-icon left>
              mdi-thumb-down
            </v-icon>
            {{ $t('meiyong') }}
          </v-btn>
          <v-spacer />
          <v-btn
            icon
            @click="closedetail"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="showticket">
            <v-divider class="opacity-3" />
            <v-card-text class="text-center">
              {{ $t('sfzxkf') }}？
              <v-spacer />
              <v-btn
                depressed
                color="primary"
                to="/ticket"
              >
                <v-icon left>
                  mdi-comment-edit
                </v-icon>
                {{ $t('tjxgd') }}
              </v-btn>
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        detail: false,
        showticket: false,
        search: '',
        category: '',
        cate_list: [],
        help_list: [],
        chooseitem: {}
      }
    },
    watch:{
      category: {
        handler(newVal, oldVal) {
          this.helps()
        }
      },
    },
    created() {
      this.keyupSubmit()
      this.helps()
    },
    mounted() {

    },
    methods: {
      keyupSubmit(){ document.onkeydown = e =>{ let _key = window.event.keyCode; if(_key === 13){ this.helps(); } } },
      helps() {
        let paramObj = {
          category: this.category,
          search: this.search
        }
        this.$server.helps(paramObj).then((response) => {
          if(response.code==200){
            this.cate_list = response.data.cates
            this.help_list = response.data.helps
          }else{
            
          }
        })
      },
      helpuseful(help_id, like) {
        if(like==1){
          this.closedetail()
        }
        if(like==-1){
          this.showticket = true
        }
        let paramObj = {
          help_id: help_id,
          like: like
        }
        this.$server.helpuseful(paramObj).then((response) => {
          if(response.code==200){
            
          }else{
            
          }
        })
      },
      seemore(key) {
        this.category = key
      },
      showdetail(item) {
        this.showticket = false
        this.chooseitem = item
        this.detail = true
      },
      closedetail() {
        this.showticket = false
        this.chooseitem = {}
        this.detail = false
      },
      doCopy: function (text) {
        this.$copyText(text).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('fzcg'))
        }, function (e) {
          console.log(e)
        })
      },
    }
  }
</script>
